@charset "UTF-8";
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  bottom: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 10px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
  padding-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  padding-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -87px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}
.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #bad9f1;
  color: rgb(0, 0, 0);
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto;
}

.react-datepicker__aria-live {
  position: absolute;
  -webkit-clip-path: circle(0);
          clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

@charset "UTF-8";
.searching-loader-1 {
  margin-top: 20px;
  padding-top: 20px;
  border-radius: 5px;
  background-color: #fafbfc;
  text-align: center;
  width: 100%;
  font-weight: bold;
}
.searching-loader-1 .img-container {
  overflow-y: hidden;
  height: 160px;
}
.searching-loader-1 .img-container img {
  max-width: 400px;
  margin-top: -80px;
}

.simple-loading-gif-container {
  display: flex;
  width: 100%;
  margin: 20px 0;
  justify-content: center;
}

.react-table {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1;
}
.react-table.mt-10 {
  margin-top: 10px;
}
.react-table.mt-20 {
  margin-top: 20px;
}
.react-table:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}
.react-table .pagination {
  color: #333333;
}
.react-table .pagination .total-records {
  float: left;
  line-height: 42px;
  padding-left: 10px;
}
.react-table .pagination .page-buttons {
  text-align: center;
  margin: 0 auto;
  padding-top: 5px;
}
.react-table .pagination .page-buttons button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #333333;
  border: 1px solid transparent;
}
.react-table .pagination .page-buttons button.current, .react-table .pagination .page-buttons button.current:hover {
  color: #333333;
  border: 1px solid #cacaca;
  background-color: white;
  background: linear-gradient(to bottom, white 0%, gainsboro 100%);
}
.react-table .pagination .page-buttons button:hover {
  color: white !important;
  border: 1px solid #111111;
  background-color: #585858;
  background: linear-gradient(to bottom, #585858 0%, #111111 100%);
}
.react-table .pagination .page-buttons button:active {
  outline: none;
  background-color: #2b2b2b;
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
  box-shadow: inset 0 0 3px #111;
}
.react-table .pagination .page-buttons button:disabled, .react-table .pagination .page-buttons button:disabled:hover, .react-table .pagination .page-buttons button:disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}
.react-table .pagination .per-page {
  float: right;
}
.react-table .pagination .per-page select {
  width: 80px;
}
.react-table .table {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
}
.react-table .table .thead .tr {
  border-bottom: 1px solid #111111;
}
.react-table .table .thead .tr .th {
  font-weight: normal;
  padding: 10px 18px 10px 6px;
  color: #7b0073;
}
.react-table .table .thead .tr .th:active {
  outline: none;
}
.react-table .table .thead .tr .th.disabled {
  color: #666666;
}
.react-table .table .thead .tr .sorting_asc,
.react-table .table .thead .tr .sorting_desc {
  cursor: pointer;
  *cursor: hand;
  font-weight: bold;
}
.react-table .table .thead .tr .sorting_asc {
  background: url("/images/arrow-sort-asc.png") no-repeat center right;
}
.react-table .table .thead .tr .sorting_desc {
  background: url("/images/arrow-sort-desc.png") no-repeat center right;
}
.react-table .table .tbody {
  border-bottom: 1px solid #111111;
}
.react-table .table .tbody .tr {
  transition: background-color 500ms linear;
}
.react-table .table .tbody .tr.odd {
  background-color: #f9f9f9;
}
.react-table .table .tbody .tr.odd.selected {
  background-color: #abb9d3;
}
.react-table .table .tbody .tr.even {
  background-color: white;
}
.react-table .table .tbody .tr.even.selected {
  background-color: #b0bed9;
}
.react-table .table .tbody .tr:hover, .react-table .table .tbody .tr.odd:hover, .react-table .table .tbody .tr.even:hover {
  background-color: whitesmoke;
}
.react-table .table .tbody .tr:hover.selected, .react-table .table .tbody .tr.odd:hover.selected, .react-table .table .tbody .tr.even:hover.selected {
  background-color: #a9b7d1;
}
.react-table .table .tbody .tr.case-is-being-viewed {
  background-color: #fff0f0;
}
.react-table .table .tbody .tr.case-is-being-viewed.odd {
  background-color: #f9f0f0;
}
.react-table .table .tbody .tr.case-is-being-viewed:hover {
  background-color: #fcecec;
}
.react-table .table .tbody .tr.case-is-being-viewed .result-row,
.react-table .table .tbody .tr.case-is-being-viewed .result-subrow,
.react-table .table .tbody .tr.case-is-being-viewed .result-query-content {
  border-left-color: #ff0000 !important;
}
.react-table .table .tbody .tr .result-row {
  clear: both;
  padding: 0px;
  display: flex;
  flex: 1 1 100%;
}
.react-table .table .tbody .tr .result-row.with-status {
  border-left: 4px solid #d3d3d3;
}
.react-table .table .tbody .tr .result-row > span {
  float: left;
  padding: 8px 10px;
  border-top: 1px solid #dddddd;
}
.react-table .table .tbody .tr .result-row > span:not(:last-of-type) {
  cursor: pointer;
}
.react-table .table .tbody .tr .result-subrow {
  font-size: 10px;
  padding: 12px 10px 16px;
  border-top: 1px dashed #ddd;
  clear: both;
  flex: 1 1 100%;
  border-left: 4px solid #d3d3d3;
}
.react-table .table .tbody .tr .result-subrow div {
  display: flex;
  flex: 1 1 100%;
}
.react-table .table .tbody .tr .result-subrow div span {
  line-height: 20px;
  color: #7C0672 !important;
}
.react-table .table .center {
  text-align: center;
}
.react-table .table .right {
  text-align: right;
}

.search-page .search-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px 20px;
}
.search-page .search-page-forms {
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
  gap: 2px;
}
.search-page .search-forms {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;
}
.search-page .search-forms > *:not(:last-child) {
  border-right: 1px solid #d8d8d8;
}
.search-page .search-forms .form-header {
  font-weight: bold;
  padding: 5px 10px 2px;
  margin: 10px 10px;
  border-bottom: 2px solid #fff;
}
.search-page .search-forms .form-description {
  padding: 5px 10px 2px;
  margin: 10px 10px;
}
.search-page .search-forms .rw-combobox {
  max-width: 272px;
}
.search-page .search-forms .rw-combobox input {
  border: none;
  border-radius: 0px;
  width: 245px !important;
}
.search-page .search-forms .rw-combobox .rw-btn .rw-picker-caret {
  max-width: 17px;
}
.search-page .search-forms .input-wrapper {
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
}
.search-page .search-forms .input-wrapper .filter-title,
.search-page .search-forms .input-wrapper label {
  margin-bottom: 2px;
  display: inline-block;
  font-weight: normal;
}
.search-page .search-forms .input-wrapper input[type=text] {
  width: 250px;
}
.search-page .search-forms .checkbox-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 5px 20px;
}
.search-page .search-forms .checkbox-wrapper.first {
  margin-top: 15px;
}
.search-page .search-forms .form-buttons {
  margin: 15px 20px;
  display: flex;
  justify-content: right;
}
.search-page .search-forms .form-buttons .rbtn {
  max-width: 100px;
}
.search-page .search-footer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 5px;
}
.search-page .search-footer .alerts-viewed-text {
  display: flex;
  line-height: 35px;
  font-weight: bold;
  grid-gap: 3px;
  gap: 3px;
}
.search-page .search-footer .alerts-viewed-text.with-filters label {
  min-width: 195px;
}
.search-page .search-footer ul.alerts-viewed-filters {
  margin-top: 6px;
}
.search-page .search-footer ul.alerts-viewed-filters li {
  margin-top: 3px;
  display: flex;
}
.search-page .search-footer ul.alerts-viewed-filters li label {
  min-width: 195px;
  line-height: 25px;
}
.search-page .search-footer ul.alerts-viewed-filters .filter-list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 3px;
  gap: 3px;
}
.search-page .search-footer ul.alerts-viewed-filters .filter-list .selected-option {
  background-color: #c8c8c8;
  color: #333;
  padding: 3px 6px;
  border-radius: 4px;
}
.search-page .search-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: right;
}
.search-page .rbtn {
  justify-self: end;
  max-width: 100px;
}
.search-page .search-by-rtpf .rbtn {
  margin: 20px auto;
  max-width: none;
  max-width: initial;
  width: 250px;
  padding: 6px 20px;
}
.search-page .search-by-rtpf .rbtn span {
  max-width: 60%;
  margin: 0 auto;
}
.search-page .show-dependants-row {
  text-align: center;
  padding: 10px;
}
.search-page .show-dependants-row button {
  background-color: transparent;
  color: #0a0;
  font-size: 13.6px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.react-queries-page .inner-tabs button {
  background-image: url(/images/tab-dark-back.png);
  background-repeat: repeat-x;
  height: 36px;
  padding: 10px 15px 5px;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border: none;
  border-bottom: #fecb27 3px solid;
  outline: none;
  font-size: 13.6px;
}
.react-queries-page .inner-tabs button.selected, .react-queries-page .inner-tabs button:hover {
  background-image: url(/images/tab-light-back.png);
  color: #666666;
  border-bottom: none !important;
  border: #fecb27 3px solid;
  margin: 0px !important;
  padding-top: 8px;
}
.react-queries-page .filters {
  padding: 20px;
}
.react-queries-page .filters .query-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
}
.react-queries-page .filters .query-options .filter-option .filter-title {
  display: inline-block;
  padding-left: 5px;
  padding-bottom: 5px;
}
.react-queries-page .filters .radio-options {
  float: left;
  margin-top: 15px;
  margin-left: 3px;
  font-weight: bold;
}
.react-queries-page .filters .radio-options ul {
  width: 620px;
  display: flex;
}
.react-queries-page .filters .radio-options label {
  float: left;
}
.react-queries-page .filters .radio-options input {
  margin: 3px 0 4px 6px;
  float: left;
}
.react-queries-page .react-table {
  margin-top: 10px;
}
.react-queries-page .react-table .type-img {
  margin: -8px -10px;
}
.react-queries-page .react-table .result-row > span {
  line-height: 29px;
  cursor: pointer;
}
.react-queries-page .react-table .result-row > span > span {
  vertical-align: middle;
  display: inline-block;
  line-height: normal;
}
.react-queries-page .react-table .result-row .expand-toggle-icon {
  width: 30px;
  height: 30px;
  display: block;
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
}
.react-queries-page .react-table .result-row .expand-toggle-icon.expand {
  background-image: url("/images/plus.png");
}
.react-queries-page .react-table .result-row .expand-toggle-icon.collapse {
  background-image: url("/images/minus.png");
}
.react-queries-page .react-table .result-row-details {
  padding: 12px 10px 16px;
  border-top: 1px dashed #ddd;
  border-left: 4px solid #d3d3d3;
}
.react-queries-page .react-table .result-row-details:hover {
  background: none;
  color: #666;
}

.react-admin-page .inner-tabs a {
  background-image: url(/images/tab-dark-back.png);
  background-repeat: repeat-x;
  box-sizing: border-box;
  height: 36px;
  padding: 10px 15px 5px;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border: none;
  border-bottom: #fecb27 3px solid;
  outline: none;
  font-size: 13.6px;
}
.react-admin-page .inner-tabs a.selected, .react-admin-page .inner-tabs a:hover {
  background-image: url(/images/tab-light-back.png);
  color: #666666;
  border: #fecb27 3px solid;
  border-bottom: none;
  margin: 0px !important;
  padding-top: 8px;
}
.react-admin-page button.link,
.react-admin-page a.link {
  color: #7C0672;
}
.react-admin-page button.link i, .react-admin-page button.link svg,
.react-admin-page a.link i,
.react-admin-page a.link svg {
  padding-right: 4px;
}
.react-admin-page button.link:hover,
.react-admin-page a.link:hover {
  color: #3b001a;
}
.react-admin-page button.link {
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.react-admin-page button.link.right {
  margin-top: 6px;
}
.react-admin-page .root-links {
  margin: 30px auto 20px;
  background-color: #ffffff;
  border-radius: 5px;
  line-height: 30px;
  padding: 20px 40px;
  width: 500px;
}
.react-admin-page .root-links p {
  padding-bottom: 10px;
}
.react-admin-page .root-links ul {
  margin-left: 20px;
}

.advise-box {
  padding: 7px 15px;
  max-width: 845px;
  margin: 0 auto;
}
.advise-box p {
  color: #333333;
  margin: 0;
  margin-bottom: 1rem;
  font-weight: bold;
}
.advise-box ul {
  list-style-type: square;
  margin-left: 20px;
}

.admin-file-upload-page .form input[type=file] {
  display: none;
}
.admin-file-upload-page .form button {
  margin: 20px auto;
}
.admin-file-upload-page .form h6 {
  font-weight: bold;
  color: #7C0672;
  font-size: 1rem;
}
.admin-file-upload-page .form .flex-table {
  width: 40%;
  margin: 0 auto;
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  grid-template-columns: minmax(120px, 1fr) minmax(120px, 6fr);
}
.admin-file-upload-page .form .flex-table .header {
  grid-column-start: 1;
  grid-column-end: 3;
}

.admin-users-page button.link,
.admin-users-page a.link {
  color: #7C0672;
}
.admin-users-page button.link i, .admin-users-page button.link svg,
.admin-users-page a.link i,
.admin-users-page a.link svg {
  padding-right: 4px;
}
.admin-users-page button.link:hover,
.admin-users-page a.link:hover {
  color: #3b001a;
}
.admin-users-page button.link {
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.admin-users-page button.link.right {
  margin-top: 6px;
}
.admin-users-page .rbtn.right,
.admin-users-page .link.right {
  float: right;
  margin-top: 4px;
}
.admin-users-page .rbtn.right + .rbtn.right, .admin-users-page .rbtn.right + .link.right,
.admin-users-page .link.right + .rbtn.right,
.admin-users-page .link.right + .link.right {
  margin-right: 10px;
}
.admin-users-page .rbtn.right {
  margin-top: 1px;
}
.admin-users-page .account-status.locked {
  color: #c00;
}
.admin-users-page .account-status.unlocked {
  color: #0a4;
}
.admin-users-page .react-table button.link {
  color: #7C0672;
  min-width: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.admin-users-page .react-table button.link:hover {
  color: #3b001a;
}
.admin-users-page .react-table button.link:disabled {
  color: #444;
  opacity: 0.2;
  cursor: not-allowed;
}
.admin-users-page .react-table a.link i, .admin-users-page .react-table a.link svg {
  padding-right: 0;
  min-width: 22px;
}
.admin-users-page .react-table a.link + a.link {
  padding-left: 0px;
}
.admin-users-page .react-table .table .thead .table-filter {
  border-top: 1px solid #111111;
  padding: 4px 0;
  min-height: 27px;
  background-color: #fcfcfc;
}
.admin-users-page .react-table .table .thead .table-filter input {
  border: 1px solid #dbdbdb;
  width: 90%;
  padding: 5px;
  border-radius: 4px;
}
.admin-users-page .react-table .table .thead .table-filter select {
  width: 90%;
  padding: 4px;
  border-radius: 4px;
}
.admin-users-page .react-table .table .tbody .tr .result-row > span {
  cursor: auto;
  cursor: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.admin-users-edit-page .user-edit-form {
  display: grid;
  width: 90%;
  margin: 0 auto;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 30px;
  column-gap: 30px;
}
.admin-users-edit-page .user-edit-form .input-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.admin-users-edit-page .user-edit-form .input-wrapper .react-select {
  width: 240px;
}
.admin-users-edit-page .user-edit-form .input-wrapper.full-row {
  grid-template-columns: repeat(4, 1fr);
}
.admin-users-edit-page .user-edit-form .input-wrapper label:first-of-type {
  margin-right: auto;
}
.admin-users-edit-page .user-edit-form .input-wrapper .account-status {
  display: flex;
  width: 240px;
  line-height: 41px;
}
.admin-users-edit-page .user-edit-form .input-wrapper .account-status button {
  background-color: #7C0672;
  border: 1px solid #6b1e5a;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0px 14px;
  height: 32px;
  align-self: center;
  margin-left: auto;
  font-size: 12px;
  cursor: pointer;
}
.admin-users-edit-page .user-edit-form .input-wrapper .account-status.locked span {
  font-weight: bold;
  color: #c00;
}
.admin-users-edit-page .user-edit-form .input-wrapper .account-status.unlocked span {
  font-weight: bold;
  color: #0c4;
}
.admin-users-edit-page .user-edit-form .input-wrapper .field-validation-error {
  grid-column-start: 2;
  grid-column-end: 3;
}
.admin-users-edit-page .user-edit-form .full-row {
  grid-column-start: 1;
  grid-column-end: 3;
}
.admin-users-edit-page .user-edit-form .full-row.radio-option {
  display: flex;
}
.admin-users-edit-page .user-edit-form .full-row.radio-option label:first-of-type {
  margin-right: 0;
  margin-right: initial;
}
.admin-users-edit-page .user-edit-form .full-row.align-center {
  justify-content: center;
}
.admin-users-edit-page .user-edit-form .full-row .form-buttons {
  margin: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}
.admin-users-edit-page .user-edit-form .full-row .form-buttons .rbtn {
  padding: 8px 12px;
  box-sizing: border-box;
  max-width: 100px;
}
.admin-users-edit-page .user-edit-form .full-row .form-buttons .rbtn svg {
  width: 30%;
}
.admin-users-edit-page .user-edit-form .full-row .form-buttons .rbtn div {
  text-align: center;
  width: 70%;
}
.admin-users-edit-page .user-edit-form label {
  line-height: 41px;
}
.admin-users-edit-page .user-edit-form label + input {
  margin-left: auto;
}
.admin-users-edit-page .user-edit-form input[type=checkbox] {
  margin-top: 13px;
}
.admin-users-edit-page .user-edit-form input[type=radio] {
  margin: 0px 8px 0 20px;
}
.admin-users-edit-page .user-edit-form input[type=radio]:first-of-type {
  margin: 0px 8px 0 40px;
}
.admin-users-edit-page .user-edit-form input[type=text],
.admin-users-edit-page .user-edit-form input[type=password] {
  max-height: 15px;
}
.admin-users-edit-page hr {
  width: 95%;
  border-top: 1px solid #ccc;
  margin: 15px auto 10px;
}

.report-page {
  padding-top: 20px;
}
.report-page .stripedbox + .stripedbox {
  margin-top: 10px;
}
.report-page .reports-performance-monitoring-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
}
.report-page .reports-performance-monitoring-form .full-width {
  grid-column: 1/-1;
}
.report-page .reports-performance-monitoring-form .flex-row {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.report-page .reports-performance-monitoring-form .flex-row > * {
  flex-grow: 1;
}
.report-page .input-wrapper .filter-title {
  font-weight: normal;
}
.report-page .input-wrapper input[type=text],
.report-page .input-wrapper select {
  box-sizing: border-box;
  width: 98%;
}
.report-page .input-wrapper.big-checkbox-wrapper {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.report-page .input-wrapper.big-checkbox-wrapper label {
  cursor: pointer;
}
.report-page .form-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 15px;
}
.report-page .form-buttons button {
  max-width: 100px;
  padding: 10px 16px;
}
.report-page .react-table {
  width: 100%;
  padding: 20px 0;
}
.report-page .react-table .pagination {
  display: none;
}
.report-page .react-table .table .thead .tr {
  background-color: #7b0073;
}
.report-page .react-table .table .thead .tr .th {
  color: #fff;
}
.report-page .react-table .table .tbody .tr .result-row > span:first-of-type {
  padding: 0;
}

.react-home-page {
  margin-top: 0px;
  box-shadow: 2px 2px 5px #ccc;
}
.react-home-page .purple-header {
  color: #fff;
  background-color: #7b0073;
  line-height: 36px;
  padding-left: 20px;
  font-weight: bold;
  width: 940px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.react-home-page .greybox {
  float: left;
  width: 920px;
  min-height: 200px;
  background-image: url("/images/back-welcome-grey-slashes.png");
  background-repeat: repeat;
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 2px 2px 5px #ccc;
}
.react-home-page .query-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 10px;
  row-gap: 10px;
}
.react-home-page .query-options .filter-option .filter-title {
  display: inline-block;
  padding-left: 5px;
  padding-bottom: 5px;
}
.react-home-page .notification-grid {
  margin: 15px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 10px;
  row-gap: 10px;
}
.react-home-page .notification-grid .divider-above {
  margin-top: 10px;
  border-top: solid 2px #ffffff;
  padding-top: 25px;
  padding-left: 13px;
  padding-bottom: 10px;
  color: #333;
  font-weight: bold;
}
.react-home-page .notification-grid .notification-item {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin: 0 20px 0 10px;
  height: 38px;
}
.react-home-page .notification-grid .notification-item .white-box {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  width: 300px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  padding: 2px 10px;
  background-color: #ffffff;
  color: #5b0053;
  line-height: 33px;
}
.react-home-page .notification-grid .notification-item .white-box .notification-icon {
  margin-top: 4px;
  font-size: 24px;
}
.react-home-page .notification-grid .notification-item .white-box .notification-text {
  width: 100%;
}
.react-home-page .notification-grid .notification-item .white-box .notification-count {
  background: #7b0073;
  min-width: 23px;
  border-radius: 12px;
  color: #FFF !important;
  text-align: center;
  line-height: 26px;
  margin: 3px 0;
  padding: 0px 5px;
}

.react-rtpf-lookup-page .rtpf-form-container {
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  gap: 25px;
  padding: 30px;
}
.react-rtpf-lookup-page .rtpf-form-container .lookup-choice {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  padding: 15px 30px 0px;
  justify-content: space-evenly;
}
.react-rtpf-lookup-page .rtpf-form-container .lookup-choice .rbtn {
  padding: 6px 20px;
  max-width: 250px;
}
.react-rtpf-lookup-page .rtpf-form-container .lookup-choice .rbtn svg {
  font-size: 20px;
}
.react-rtpf-lookup-page .rtpf-form-container .two-by-two-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px 40px;
  gap: 15px 40px;
}
.react-rtpf-lookup-page .rtpf-form-container .two-by-two-grid .input-wrapper {
  display: flex;
  align-items: baseline;
  grid-gap: 10px;
  gap: 10px;
}
.react-rtpf-lookup-page .rtpf-form-container .two-by-two-grid .input-wrapper label {
  width: 175px;
}
.react-rtpf-lookup-page .rtpf-form-container .input-wrapper label {
  display: inline-block;
  margin-bottom: 5px;
}
.react-rtpf-lookup-page .rtpf-form-container .input-wrapper .input-with-error-message {
  grid-gap: 5px;
  gap: 5px;
  width: 220px;
}
.react-rtpf-lookup-page .rtpf-form-container .input-wrapper input {
  width: 100%;
  box-sizing: border-box;
}
.react-rtpf-lookup-page .rtpf-form-container .form-buttons {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  margin: 0 auto;
  align-items: flex-start;
}
.react-rtpf-lookup-page .rtpf-form-container .form-buttons button {
  padding: 10px 16px;
}
.react-rtpf-lookup-page .rtpf-form-container hr {
  border-top: 1px solid #ccc;
}
.react-rtpf-lookup-page .rtpf-result-page .top-menu {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.react-rtpf-lookup-page .rtpf-result-page .top-menu .rbtn {
  max-width: 150px;
  padding: 8px 20px;
}
.react-rtpf-lookup-page .error-message {
  margin: 30px 30px 10px;
  padding: 10px;
  border: solid 1px #ff0000;
  background-color: #fde5e5;
  color: #ff0000;
  text-align: center;
  font-weight: bold;
}

.react-rtpf-lookup-modal .performing-lookup-modal h2 {
  font-size: 16px;
  color: #000;
}
.react-rtpf-lookup-modal .performing-lookup-modal .performing-lookup-row {
  border-top: 5px solid #7c0672;
  background-color: white;
  padding: 10px 20px;
  margin-top: 20px;
}
.react-rtpf-lookup-modal .performing-lookup-modal .performing-lookup-row .status {
  display: flex;
  justify-content: space-between;
  color: #000;
  cursor: pointer;
}
.react-rtpf-lookup-modal .performing-lookup-modal .performing-lookup-row .status .result-icon {
  padding: 0 20px;
}
.react-rtpf-lookup-modal .performing-lookup-modal .performing-lookup-row ul {
  margin: 20px auto 10px;
}
.react-rtpf-lookup-modal .performing-lookup-modal .performing-lookup-row ul li {
  display: grid;
  grid-template-columns: 3fr 4fr;
  padding: 6px 12px;
}
.react-rtpf-lookup-modal .performing-lookup-modal .performing-lookup-row ul li label {
  font-weight: bold;
}
.react-rtpf-lookup-modal .performing-lookup-modal .performing-lookup-row ul li .missing {
  color: #eebb17;
  font-weight: bold;
}
.react-rtpf-lookup-modal .performing-lookup-modal .performing-lookup-row ul li + li {
  border-top: 1px solid #b1b4b6;
}

.react-rtpf-lookup-page .home-office-record,
.react-rtpf-lookup-modal .home-office-record {
  margin-top: 10px;
  border-top: solid 5px #9325b2;
  background-color: white;
  padding: 30px 50px;
  font-size: 19px;
  color: #0b0c0c;
}
.react-rtpf-lookup-page .home-office-record h2,
.react-rtpf-lookup-modal .home-office-record h2 {
  font-size: 24px;
}
.react-rtpf-lookup-page .home-office-record .top-section,
.react-rtpf-lookup-modal .home-office-record .top-section {
  display: flex;
  justify-content: space-between;
}
.react-rtpf-lookup-page .home-office-record .top-section ul.details-list,
.react-rtpf-lookup-modal .home-office-record .top-section ul.details-list {
  width: 560px;
}
.react-rtpf-lookup-page .home-office-record .top-section ul.details-list li,
.react-rtpf-lookup-modal .home-office-record .top-section ul.details-list li {
  grid-template-columns: 1fr 2fr;
}
.react-rtpf-lookup-page .home-office-record .top-section img,
.react-rtpf-lookup-modal .home-office-record .top-section img {
  max-width: 170px;
  align-self: flex-start;
}
.react-rtpf-lookup-page .home-office-record ul.details-list,
.react-rtpf-lookup-modal .home-office-record ul.details-list {
  margin: 20px;
}
.react-rtpf-lookup-page .home-office-record ul.details-list li,
.react-rtpf-lookup-modal .home-office-record ul.details-list li {
  display: grid;
  grid-template-columns: 3fr 8fr;
  padding: 6px 12px;
}
.react-rtpf-lookup-page .home-office-record ul.details-list li label,
.react-rtpf-lookup-modal .home-office-record ul.details-list li label {
  font-weight: bold;
}
.react-rtpf-lookup-page .home-office-record ul.details-list li + li,
.react-rtpf-lookup-modal .home-office-record ul.details-list li + li {
  border-top: 1px solid #b1b4b6;
}
.react-rtpf-lookup-page .home-office-record .no-records,
.react-rtpf-lookup-modal .home-office-record .no-records {
  display: inline-block;
  padding: 10px;
}
.react-rtpf-lookup-page .home-office-record .digital-record,
.react-rtpf-lookup-modal .home-office-record .digital-record {
  margin: 20px 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: solid 2px #a82612;
}
.react-rtpf-lookup-page .home-office-record .digital-record.yes,
.react-rtpf-lookup-modal .home-office-record .digital-record.yes {
  border-color: #00703c;
}
.react-rtpf-lookup-page .home-office-record .digital-record.expired,
.react-rtpf-lookup-modal .home-office-record .digital-record.expired {
  border-color: #807E7C;
  color: #807E7C;
}
.react-rtpf-lookup-page .home-office-record .digital-record .header,
.react-rtpf-lookup-modal .home-office-record .digital-record .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 3px solid #a82612;
  color: #a82612;
  padding: 10px;
}
.react-rtpf-lookup-page .home-office-record .digital-record .header.yes,
.react-rtpf-lookup-modal .home-office-record .digital-record .header.yes {
  border-top-color: #00703c;
  color: #00703c;
}
.react-rtpf-lookup-page .home-office-record .digital-record .header.expired,
.react-rtpf-lookup-modal .home-office-record .digital-record .header.expired {
  border-top-color: #807E7C;
  color: #807E7C;
}
.react-rtpf-lookup-page .home-office-record .digital-record .header .title,
.react-rtpf-lookup-modal .home-office-record .digital-record .header .title {
  display: flex;
  align-items: center;
}
.react-rtpf-lookup-page .home-office-record .digital-record .header .title svg,
.react-rtpf-lookup-modal .home-office-record .digital-record .header .title svg {
  font-size: 28px;
  padding: 0px 10px;
}
.react-rtpf-lookup-page .home-office-record .digital-record .header .stamp,
.react-rtpf-lookup-modal .home-office-record .digital-record .header .stamp {
  font-weight: 700;
  font-family: "Courier New", monospace;
  font-size: 1.5rem;
  transform: rotate(-3deg);
}

.superuser-users-page button.link,
.superuser-users-page a.link {
  color: #7C0672;
}
.superuser-users-page button.link i, .superuser-users-page button.link svg,
.superuser-users-page a.link i,
.superuser-users-page a.link svg {
  padding-right: 4px;
}
.superuser-users-page button.link:hover,
.superuser-users-page a.link:hover {
  color: #3b001a;
}
.superuser-users-page button.link {
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.superuser-users-page button.link.right {
  margin-top: 6px;
}
.superuser-users-page .rbtn.right,
.superuser-users-page .link.right {
  float: right;
  margin-top: 4px;
}
.superuser-users-page .rbtn.right + .rbtn.right, .superuser-users-page .rbtn.right + .link.right,
.superuser-users-page .link.right + .rbtn.right,
.superuser-users-page .link.right + .link.right {
  margin-right: 10px;
}
.superuser-users-page .rbtn.right {
  margin-top: 1px;
}
.superuser-users-page .account-status.locked {
  color: #c00;
}
.superuser-users-page .account-status.unlocked {
  color: #0a4;
}
.superuser-users-page .react-table button.link {
  color: #7C0672;
  min-width: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.superuser-users-page .react-table button.link:hover {
  color: #3b001a;
}
.superuser-users-page .react-table button.link:disabled {
  color: #444;
  opacity: 0.2;
  cursor: not-allowed;
}
.superuser-users-page .react-table a.link i, .superuser-users-page .react-table a.link svg {
  padding-right: 0;
  min-width: 22px;
}
.superuser-users-page .react-table a.link + a.link {
  padding-left: 0px;
}
.superuser-users-page .react-table .table .thead .table-filter {
  border-top: 1px solid #111111;
  padding: 4px 0;
  min-height: 27px;
  background-color: #fcfcfc;
}
.superuser-users-page .react-table .table .thead .table-filter input {
  border: 1px solid #dbdbdb;
  width: 90%;
  padding: 5px;
  border-radius: 4px;
}
.superuser-users-page .react-table .table .thead .table-filter select {
  width: 90%;
  padding: 4px;
  border-radius: 4px;
}
.superuser-users-page .react-table .table .tbody .tr .result-row > span {
  cursor: auto;
  cursor: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.superuser-users-edit-page .user-edit-form {
  display: grid;
  width: 90%;
  margin: 0 auto;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 30px;
  column-gap: 30px;
}
.superuser-users-edit-page .user-edit-form .input-wrapper {
  display: grid;
  grid-template-columns: 2fr 3fr;
}
.superuser-users-edit-page .user-edit-form .input-wrapper input[type=text],
.superuser-users-edit-page .user-edit-form .input-wrapper input[type=password] {
  width: auto;
}
.superuser-users-edit-page .user-edit-form .input-wrapper label:first-of-type {
  margin-right: auto;
}
.superuser-users-edit-page .user-edit-form .input-wrapper .account-status {
  display: flex;
  width: 240px;
  line-height: 41px;
}
.superuser-users-edit-page .user-edit-form .input-wrapper .account-status button {
  background-color: #7C0672;
  border: 1px solid #6b1e5a;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0px 14px;
  height: 32px;
  align-self: center;
  margin-left: auto;
  font-size: 12px;
  cursor: pointer;
}
.superuser-users-edit-page .user-edit-form .input-wrapper .account-status.locked span {
  font-weight: bold;
  color: #c00;
}
.superuser-users-edit-page .user-edit-form .input-wrapper .account-status.unlocked span {
  font-weight: bold;
  color: #0a4;
}
.superuser-users-edit-page .user-edit-form .input-wrapper .field-validation-error {
  grid-column-start: 2;
  grid-column-end: 3;
}
.superuser-users-edit-page .user-edit-form .full-row {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
}
.superuser-users-edit-page .user-edit-form .full-row label:first-of-type {
  margin-right: 0;
  margin-right: initial;
}
.superuser-users-edit-page .user-edit-form .full-row input[type=radio]:first-of-type {
  margin-left: 30px;
}
.superuser-users-edit-page .user-edit-form .full-row.align-center {
  justify-content: center;
}
.superuser-users-edit-page .user-edit-form .full-row .form-buttons {
  margin: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.superuser-users-edit-page .user-edit-form .full-row .form-buttons .rbtn {
  padding: 8px 12px;
  box-sizing: border-box;
  width: 100px;
}
.superuser-users-edit-page .user-edit-form .full-row .form-buttons .rbtn svg {
  width: 30%;
}
.superuser-users-edit-page .user-edit-form .full-row .form-buttons .rbtn div {
  text-align: center;
  width: 70%;
}
.superuser-users-edit-page .user-edit-form label {
  line-height: 41px;
}
.superuser-users-edit-page .user-edit-form label + input {
  margin-left: auto;
}
.superuser-users-edit-page .user-edit-form input[type=checkbox] {
  margin-top: 13px;
}
.superuser-users-edit-page .user-edit-form input[type=radio] {
  margin: 0 8px 0 20px;
}
.superuser-users-edit-page .user-edit-form input[type=text],
.superuser-users-edit-page .user-edit-form input[type=password] {
  max-height: 15px;
}
.superuser-users-edit-page hr {
  width: 95%;
  border-top: 1px solid #ccc;
  margin: 15px auto 10px;
}

.superuser-rtpf-descriptions-page .header-with-button {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px 20px;
}
.superuser-rtpf-descriptions-page .description-summary-rows {
  width: 90%;
  margin: 20px auto;
  border-collapse: collapse;
  display: table;
}
.superuser-rtpf-descriptions-page .description-summary-rows .header {
  font-size: 12px;
  color: #333;
  display: table-header-group;
}
.superuser-rtpf-descriptions-page .description-summary-rows .header span {
  display: table-cell;
  padding-left: 8px;
  color: #7C0672;
}
.superuser-rtpf-descriptions-page .description-summary-rows .body-row {
  display: table-row;
}
.superuser-rtpf-descriptions-page .description-summary-rows .body-row > span {
  display: table-cell;
  background-color: #fff;
  border-top: 1px solid #888;
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
  overflow-wrap: anywhere;
  max-width: 110px;
}
.superuser-rtpf-descriptions-page .description-summary-rows .body-row:last-child > span {
  border-bottom: 1px solid #888;
}
.superuser-rtpf-descriptions-page .description-summary-rows .body-row .list-buttons {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  align-items: flex-start;
}
.superuser-rtpf-descriptions-page .description-summary-rows .rbtn span {
  text-align: center;
}
.superuser-rtpf-descriptions-page .description-summary-rows.no-records {
  display: block;
}
.superuser-rtpf-descriptions-page .description-summary-rows.no-records .header {
  display: block;
}
.superuser-rtpf-descriptions-page .description-summary-rows.no-records .header span {
  display: inline-block;
  margin-right: 40px;
}
.superuser-rtpf-descriptions-page .description-summary-rows.no-records .body-row {
  display: block;
}
.superuser-rtpf-descriptions-page .description-summary-rows.no-records .body-row > span {
  display: block;
  text-align: center;
  line-height: 28px;
}

.react-case-summary-page .summary-header {
  display: flex;
  justify-content: space-between;
}
.react-case-summary-page .summary-header .case-details-left {
  width: 660px;
  padding: 10px;
}
.react-case-summary-page .summary-header .case-details-left .case-details-flex-row {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.react-case-summary-page .summary-header .case-details-left .case-details-flex-row label {
  width: 127px;
  box-sizing: border-box;
}
.react-case-summary-page .summary-header .case-details-left .case-details-flex-row + .case-details-flex-row, .react-case-summary-page .summary-header .case-details-left .case-details-flex-row + .case-details-grid-row {
  margin-top: 10px;
}
.react-case-summary-page .summary-header .case-details-left .case-details-flex-row .input-with-button {
  display: flex;
  width: 523px;
  grid-gap: 5px;
  gap: 5px;
}
.react-case-summary-page .summary-header .case-details-left .case-details-flex-row .input-with-button .fake-input {
  min-width: 380px;
  width: 100%;
}
.react-case-summary-page .summary-header .case-details-left .case-details-flex-row .input-with-button .rbtn {
  margin-top: 2px;
  margin-bottom: 2px;
}
.react-case-summary-page .summary-header .case-details-left .case-details-grid-row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  grid-gap: 10px;
  gap: 10px;
}
.react-case-summary-page .summary-header .case-details-left .case-details-grid-row label {
  width: 127px;
}
.react-case-summary-page .summary-header .case-details-left .case-details-grid-row .fake-input {
  width: 163px;
}
.react-case-summary-page .summary-header .case-details-left .case-details-grid-row + .case-details-flex-row, .react-case-summary-page .summary-header .case-details-left .case-details-grid-row + .case-details-grid-row {
  margin-top: 10px;
}
.react-case-summary-page .summary-header .case-details-left .case-details-grid-row .input-with-button {
  display: flex;
  width: 185px;
  grid-gap: 5px;
  gap: 5px;
}
.react-case-summary-page .summary-header .case-details-left .case-details-grid-row .input-with-button .fake-input {
  width: 100%;
}
.react-case-summary-page .summary-header .case-details-left .case-details-grid-row .input-with-button .rbtn {
  margin-top: 2px;
  margin-bottom: 2px;
}
.react-case-summary-page .summary-header .case-details-left label {
  line-height: 40px;
}
.react-case-summary-page .summary-header .case-details-right {
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
}
.react-case-summary-page .summary-header .case-details-right .case-details-right-top {
  align-self: end;
  position: relative;
}
.react-case-summary-page .summary-header .case-details-right .case-details-right-top .closed-badge {
  position: absolute;
  top: 45px;
  left: -10px;
  width: 210px;
  height: 122px;
  background: transparent url(/images/case-closed-stamp.png) no-repeat top left;
  z-index: 100;
}
.react-case-summary-page .summary-header .case-details-right .photo-image {
  position: relative;
  width: 195px;
}
.react-case-summary-page .summary-header .case-details-right .photo-image img {
  width: 195px;
  height: 195px;
}
.react-case-summary-page .summary-header .case-details-right .photo-image .img-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 195px;
  height: 195px;
  background-color: #000;
  color: #fff;
  transition: 0.3s ease;
  opacity: 0;
}
.react-case-summary-page .summary-header .case-details-right .photo-image .img-overlay-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  transition: 0.3s ease;
  opacity: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.react-case-summary-page .summary-header .case-details-right .photo-image.changeable {
  cursor: pointer;
}
.react-case-summary-page .summary-header .case-details-right .photo-image.changeable:hover img {
  opacity: 0.5;
}
.react-case-summary-page .summary-header .case-details-right .photo-image.changeable:hover .img-overlay {
  opacity: 0.5;
}
.react-case-summary-page .summary-header .case-details-right .photo-image.changeable:hover .img-overlay-text {
  opacity: 1;
}
.react-case-summary-page .summary-header .case-details-right .photo-image.closed img {
  opacity: 0.3;
}
.react-case-summary-page .summary-header .case-details-right .referral-badge {
  text-align: center;
  color: green;
  font-weight: bolder;
  font-size: 34px;
}
.react-case-summary-page .summary-header .case-details-right .rbtn.allocation {
  width: 100%;
  padding: 10px;
  margin-top: 15px;
}
.react-case-summary-page .summary-header .case-details-right .age-stamp {
  margin-top: 10px;
  border: 2px solid #f80;
  padding: 5px;
  font-size: 12px;
  float: right;
  width: 215px;
  text-align: center;
  font-weight: bold;
  color: #000;
  background-color: #c9c9c9;
  border-radius: 4px;
}
.react-case-summary-page .summary-header .case-details-right .age-stamp > span {
  margin-top: 5px;
  font-size: 20px;
}
.react-case-summary-page .summary-header .case-details-right .age-stamp.green {
  background-color: #c5e0b4;
}
.react-case-summary-page .summary-header .case-details-right .age-stamp.orange {
  background-color: #f4b183;
}
.react-case-summary-page .summary-header .case-details-right .age-stamp span.text-red {
  color: #f00;
}
.react-case-summary-page .caseworker-details-modal {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 10px;
  gap: 10px;
}
.react-case-summary-page .caseworker-details-modal .label {
  line-height: 40px;
}
.react-case-summary-page .alert-advise-modal .grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-row-gap: 20px;
  row-gap: 20px;
  padding: 10px;
}
.react-case-summary-page .alert-advise-modal .grid .heading {
  font-weight: bold;
  color: #7C0672;
}
.react-case-summary-page .summary-footer {
  display: flex;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 15px;
}
.react-case-summary-page .summary-footer .footer-column {
  width: 600px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.react-case-summary-page .summary-footer .footer-column.left {
  width: 600px;
}
.react-case-summary-page .summary-footer .footer-column.right {
  width: 340px;
}
.react-case-summary-page .summary-footer .summary-title {
  display: flex;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
  padding-left: 5px;
  line-height: 30px;
  margin-bottom: 2px;
}
.react-case-summary-page .summary-footer .summary-title svg {
  color: #7C0672;
}
.react-case-summary-page .summary-footer .summary-title span.title {
  color: #7C0672;
}
.react-case-summary-page .summary-footer .summary-title span.title svg {
  font-size: 18px;
  margin-right: 7px;
}
.react-case-summary-page .summary-footer .summary-title span.small {
  font-size: 12px;
}
.react-case-summary-page .case-dates {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.react-case-summary-page .case-dates .legislation-input {
  margin-top: 5px;
}
.react-case-summary-page .case-dates .date-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  column-gap: 10px;
}
.react-case-summary-page .case-dates .bold-red {
  font-weight: bold;
  color: red !important;
}
.react-case-summary-page .case-dates .costs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 5px;
  row-gap: 5px;
  grid-column-gap: 10px;
  column-gap: 10px;
}
.react-case-summary-page .case-dates .costs-grid .with-money-value {
  display: flex;
  justify-content: space-between;
}
.react-case-summary-page .alerts-wrapper {
  border: 2px solid orange;
}
.react-case-summary-page .case-alerts {
  display: flex;
  flex-direction: column;
}
.react-case-summary-page .case-alerts .alert-row {
  display: flex;
  justify-content: space-between;
}
.react-case-summary-page .case-alerts .alert-row .header {
  line-height: 30px;
}
.react-case-summary-page .case-alerts .alert-row .header .title.bold-red {
  font-weight: bold;
  color: red;
}
.react-case-summary-page .case-alerts .alert-row .header .title.bold-purple {
  font-weight: bold;
  color: #7c0672;
}
.react-case-summary-page .case-alerts .alert-row .header .days {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  color: blue;
}
.react-case-summary-page .case-alerts .alert-row .header .days.green {
  color: green;
}
.react-case-summary-page .case-alerts .alert-row .header .days.red {
  color: red;
}
.react-case-summary-page .case-alerts .alert-row .view-alert-link {
  color: #7c0672;
  line-height: 30px;
  font-weight: bold;
}
.react-case-summary-page .case-alerts .alert-row a.close-alert-link,
.react-case-summary-page .case-alerts .alert-row a.close-alert-link:visited {
  font-weight: normal;
  color: #7C0672;
  text-decoration: none;
  margin-left: 10px;
  font-size: 12px;
}
.react-case-summary-page .case-alerts .alert-row a.close-alert-link:hover {
  color: #7C0672;
  text-decoration: underline;
}
.react-case-summary-page .case-alerts .alert-row .tick {
  width: 26px;
  height: 26px;
  background: transparent url("/images/tick-small.png") no-repeat 0px 1px;
}
.react-case-summary-page .case-alerts .alert-row .cross {
  width: 26px;
  height: 26px;
  background: transparent url("/images/round-cross.png") no-repeat;
}
.react-case-summary-page .case-alerts .alert-row .alert-reason {
  color: #7c0672;
  font-size: 12px;
  line-height: 24px;
}
.react-case-summary-page .case-alerts .alert-row .list {
  display: grid;
  grid-template-columns: 1fr 3fr;
  line-height: 30px;
  margin-top: 2px;
}
.react-case-summary-page .case-alerts .alert-row .list .value {
  text-align: right;
  color: #7C0672;
}
.react-case-summary-page .case-alerts .alert-row .check-list {
  display: flex;
  flex-direction: column;
}
.react-case-summary-page .case-alerts .alert-row .check-list .check-list-item {
  display: flex;
  line-height: 30px;
  justify-content: space-between;
}
.react-case-summary-page .case-alerts .alert-row .check-list .check-list-item a {
  color: #666;
  text-decoration: none;
}
.react-case-summary-page .case-alerts .alert-row .check-list .check-list-item a:hover {
  text-decoration: underline;
}
.react-case-summary-page .case-alerts .alert-row.with-rows {
  flex-direction: column;
}
.react-case-summary-page .case-alerts .alert-row + .alert-row {
  border-top: 2px solid #fff;
  margin-top: 4px;
  padding-top: 6px;
}
.react-case-summary-page .react-crop-container img {
  max-width: 100%;
}
.react-case-summary-page .react-crop-container .image-controls {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.dependants-summary-rows {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  display: table;
}
.dependants-summary-rows .header {
  font-size: 12px;
  color: #333;
  display: table-header-group;
}
.dependants-summary-rows .header span {
  display: table-cell;
  padding-left: 8px;
  line-height: 30px;
}
.dependants-summary-rows .body-row {
  display: table-row;
}
.dependants-summary-rows .body-row > span {
  display: table-cell;
  background-color: #fff;
  border-top: 1px solid #888;
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}
.dependants-summary-rows .body-row:last-child > span {
  border-bottom: 1px solid #888;
}
.dependants-summary-rows .body-row .list-buttons {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  align-items: flex-start;
}
.dependants-summary-rows .rbtn span {
  text-align: center;
}
.dependants-summary-rows.no-records {
  display: block;
}
.dependants-summary-rows.no-records .header {
  display: block;
}
.dependants-summary-rows.no-records .header span {
  display: inline-block;
  margin-right: 40px;
}
.dependants-summary-rows.no-records .body-row {
  display: block;
}
.dependants-summary-rows.no-records .body-row > span {
  display: block;
  text-align: center;
  line-height: 28px;
}

.react-case-dependants-page .dependants-summary-rows {
  width: 90%;
  margin: 20px auto;
}

.dependant-edit-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
  gap: 2px;
}
.dependant-edit-wrapper .dependant-grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
  gap: 2px;
}
.dependant-edit-wrapper .dependant-grid-wrapper hr {
  border-color: #fff;
  border-style: solid;
}
.dependant-edit-wrapper .dependant-grid-wrapper .dependant-edit-form {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding: 0px 8px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .header-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dependant-edit-wrapper .dependant-grid-wrapper .left-column .input-wrapper-with-options {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .left-column .input-wrapper-with-options .dropdown-options {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  justify-content: flex-end;
}
.dependant-edit-wrapper .dependant-grid-wrapper .left-column .input-wrapper {
  display: grid;
  grid-template-columns: 45fr 55fr;
  grid-gap: 10px;
  gap: 10px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .left-column .input-wrapper label {
  align-self: center;
}
.dependant-edit-wrapper .dependant-grid-wrapper .left-column .input-wrapper input[type=text] {
  width: auto;
}
.dependant-edit-wrapper .dependant-grid-wrapper .left-column .input-wrapper .react-datepicker__input-container input {
  width: 216px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .left-column .input-wrapper .rw-dropdown-list-value {
  line-height: 39px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .left-column .fake-input {
  white-space: pre-wrap;
}
.dependant-edit-wrapper .dependant-grid-wrapper .right-column {
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
  gap: 2px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .right-column .input-wrapper {
  display: grid;
  grid-template-columns: 45fr 55fr;
  grid-gap: 10px;
  gap: 10px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .right-column .input-wrapper label {
  align-self: center;
}
.dependant-edit-wrapper .dependant-grid-wrapper .right-column .input-wrapper input[type=text] {
  width: auto;
}
.dependant-edit-wrapper .dependant-grid-wrapper .right-column .input-wrapper .react-datepicker__input-container input {
  width: 180px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .right-column .input-wrapper .rw-dropdown-list-value {
  line-height: 39px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .right-column .header-with-button .rbtn {
  margin-right: 5px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .right-column .header-with-button svg {
  color: #7C0672;
}
.dependant-edit-wrapper .dependant-grid-wrapper .impairment-groups {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 10px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .impairment-groups .input-wrapper {
  display: flex;
}
.dependant-edit-wrapper .dependant-grid-wrapper .impairment-groups .input-wrapper .regular-checkbox-box {
  align-self: initial;
}
.dependant-edit-wrapper .dependant-grid-wrapper .aliases,
.dependant-edit-wrapper .dependant-grid-wrapper .documents {
  padding: 5px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .alias,
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .document,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .alias,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .document {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  box-shadow: 1px 1px 3px #ccc;
  border-radius: 5px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .alias .header,
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .document .header,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .alias .header,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .document .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .alias .header .alias-description,
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .alias .header .document-description,
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .document .header .alias-description,
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .document .header .document-description,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .alias .header .alias-description,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .alias .header .document-description,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .document .header .alias-description,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .document .header .document-description {
  line-height: 29px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .alias .alias-user-details,
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .alias .document-user-details,
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .document .alias-user-details,
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .document .document-user-details,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .alias .alias-user-details,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .alias .document-user-details,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .document .alias-user-details,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .document .document-user-details {
  color: #aaa;
  font-size: 10px;
  padding: 0px 10px 5px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .alias .alias-buttons,
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .alias .document-buttons,
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .document .alias-buttons,
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .document .document-buttons,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .alias .alias-buttons,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .alias .document-buttons,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .document .alias-buttons,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .document .document-buttons {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  align-items: flex-start;
}
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .alias + .alias, .dependant-edit-wrapper .dependant-grid-wrapper .aliases .alias + .document,
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .document + .alias,
.dependant-edit-wrapper .dependant-grid-wrapper .aliases .document + .document,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .alias + .alias,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .alias + .document,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .document + .alias,
.dependant-edit-wrapper .dependant-grid-wrapper .documents .document + .document {
  margin-top: 3px;
}
.dependant-edit-wrapper .dependant-grid-wrapper .queries-control {
  padding: 10px 0;
}
.dependant-edit-wrapper .form-buttons {
  padding: 5px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}
.dependant-edit-wrapper .form-buttons .rbtn {
  padding: 8px 12px;
  max-width: 100px;
}
.dependant-edit-wrapper .queries-control-title {
  margin-top: 10px;
}

.address-history {
  width: 90%;
  margin: 20px auto;
  border-collapse: collapse;
}
.address-history .rbtn.right + .rbtn.right {
  margin-right: 10px;
}
.address-history .table .tbody .tr {
  line-height: 29px;
}
.address-history .table .tbody .tr .current-address {
  font-weight: bold;
}
.address-history .finance-summary-rows {
  width: 90%;
  margin: 8px auto;
  border-collapse: collapse;
  display: table;
}
.address-history .finance-summary-rows .header {
  font-size: 12px;
  color: #7C0672;
  display: table-header-group;
}
.address-history .finance-summary-rows .header span {
  display: table-cell;
  padding-left: 8px;
}
.address-history .finance-summary-rows .body-row {
  display: table-row;
}
.address-history .finance-summary-rows .body-row > span {
  display: table-cell;
  background-color: #fff;
  border-top: 1px solid #888;
  border-bottom: 1px solid #ddd;
  padding: 8px;
}
.address-history .finance-summary-rows .body-row:last-child > span {
  border-bottom: 1px solid #888;
}
.address-history .finance-summary-rows .rbtn span {
  text-align: center;
}

.case-address-edit-page .address-edit-form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 10px auto;
  grid-gap: 10px;
  gap: 10px;
}
.case-address-edit-page .address-edit-form .input-wrapper {
  display: flex;
}
.case-address-edit-page .address-edit-form .input-wrapper label {
  width: 40%;
  line-height: 40px;
}
.case-address-edit-page .address-edit-form .input-wrapper .rw-dropdown-list {
  width: 240px;
}
.case-address-edit-page .address-edit-form .input-wrapper .rw-dropdown-list input {
  color: #000;
}
.case-address-edit-page .address-edit-form .input-wrapper .react-select {
  width: 240px;
}
.case-address-edit-page .address-edit-form .radio-options {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.case-address-edit-page .address-edit-form .input-with-error-message {
  max-width: 240px;
}
.case-address-edit-page .address-edit-form .input-with-button {
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 240px !important;
  grid-gap: 10px;
  gap: 10px;
}
.case-address-edit-page .address-edit-form .input-with-button input[type=text] {
  width: 115px;
}
.case-address-edit-page .address-edit-form .input-with-button button {
  margin-top: 3px;
  height: 35px;
}
.case-address-edit-page .search-field input {
  border: none;
  width: 213px;
}
.case-address-edit-page hr {
  border-color: #fff;
  border-style: solid;
}
.case-address-edit-page .address-display {
  width: 220px;
}
.case-address-edit-page .address-display .full-address {
  float: left;
  color: #000;
}
.case-address-edit-page .address-display .rbtn {
  float: right;
}
.case-address-edit-page .form-buttons {
  margin: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}
.case-address-edit-page .form-buttons .rbtn {
  padding: 8px 12px;
  box-sizing: border-box;
  max-width: 100px;
}
.case-address-edit-page .select-with-options {
  display: flex;
  flex-direction: column;
}
.case-address-edit-page .select-with-options .select-with-options-buttons {
  padding: 10px 0;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.queries-control-title {
  display: flex;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
  padding-left: 5px;
  line-height: 30px;
  margin-bottom: 2px;
}
.queries-control-title svg {
  color: #7C0672;
}
.queries-control-title span.title {
  color: #7C0672;
}
.queries-control-title span.title svg {
  font-size: 18px;
  margin-right: 7px;
}
.queries-control-title span.small {
  font-size: 12px;
}

.queries-control ul.vertical-scroll {
  height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}
.queries-control ul.vertical-scroll li.query-row {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}
.queries-control ul.vertical-scroll li.query-row .icon {
  display: inline-block;
  width: 25px;
}
.queries-control ul.vertical-scroll li.query-row .icon svg {
  font-size: 16px;
  color: #7C0672;
}
.queries-control ul.vertical-scroll li.query-row .status-complete {
  font-size: 10px;
  padding-left: 5px;
  color: #7C0672;
  font-weight: bold;
}
.queries-control ul.vertical-scroll li.query-row .days-elapsed {
  font-weight: bold;
  color: #0a0;
}
.queries-control ul.vertical-scroll li.query-row .days-elapsed.red {
  color: red;
}
.queries-control ul.vertical-scroll li.query-row + li.query-row {
  margin-top: 3px;
}

.queries-add {
  display: flex;
  flex-direction: column;
}
.queries-add .queries-form {
  width: 450px;
}
.queries-add .queries-columns {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-direction: row;
}
.queries-add .queries-columns .queries-help-text {
  width: 370px;
  padding-left: 15px;
  line-height: 1.3em;
  border-left: 1px dashed #ccc;
}
.queries-add .queries-columns .queries-help-text h2,
.queries-add .queries-columns .queries-help-text p,
.queries-add .queries-columns .queries-help-text ul {
  margin-bottom: 8px;
}
.queries-add .queries-columns .queries-help-text ul {
  list-style-type: square;
  margin-left: 20px;
}
.queries-add .queries-columns .queries-help-text ul li {
  margin-bottom: 5px;
}

.result-query-content {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.result-query-content .rounded-box {
  background-color: #fff;
  padding: 10px;
  box-shadow: 2px 2px 5px #ccc;
  border-radius: 5px;
}
.result-query-content .header .details {
  height: 40px;
  display: flex;
  grid-gap: 6px;
  gap: 6px;
}
.result-query-content .header .details .post-count {
  text-align: center;
  width: 50px;
  font-size: 10px;
}
.result-query-content .header .details .post-count .post-count-number {
  font-size: 16px;
}
.result-query-content .header .details .topic-name {
  min-width: 225px;
  padding-left: 6px;
  padding-right: 6px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.result-query-content .header .details .topic-name .topic-name-title {
  font-size: 18px;
  color: #7C0672;
}
.result-query-content .header .details .references {
  min-width: 205px;
  line-height: 20px;
}
.result-query-content .header .details .references > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.result-query-content .header .details .creation-details {
  min-width: 265px;
  line-height: 20px;
}
.result-query-content .header .details .creation-details > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.result-query-content .header .reply {
  display: flex;
  align-items: flex-end;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 20px 10px 20px;
}
.result-query-content .header .reply textarea {
  min-width: 660px;
}
.result-query-content .complete {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.result-query-content .complete .prompt {
  font-weight: bold;
}
.result-query-content .complete .rbtn {
  max-width: 120px;
}
.result-query-content .queries {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 10px;
}
.result-query-content .queries .message-row .message {
  width: 500px;
  position: relative;
  padding: 10px 20px 30px;
  border: 1px solid #5b0053;
  background-color: #fff;
  line-height: 22px;
  border-radius: 5px;
}
.result-query-content .queries .message-row .message .name {
  font-weight: bold;
  color: #000;
  padding-right: 5px;
}
.result-query-content .queries .message-row .message .creation-date {
  position: absolute;
  right: 20px;
  bottom: 5px;
}
.result-query-content .queries .message-row .message .team-name {
  position: absolute;
  left: 20px;
  bottom: 5px;
}
.result-query-content .queries .message-row.sent {
  justify-self: left;
}
.result-query-content .queries .message-row.sent .message {
  margin-left: 50px;
}
.result-query-content .queries .message-row.sent .message:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -30px;
  top: 20%;
  border-right: 30px solid #5b0053;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.result-query-content .queries .message-row.sent .message:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -27px;
  top: 20%;
  border-right: 30px solid #fff;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.result-query-content .queries .message-row.received {
  justify-self: right;
}
.result-query-content .queries .message-row.received .message {
  margin-right: 50px;
  border: 1px solid #666;
  background-color: #f0f0f0;
}
.result-query-content .queries .message-row.received .message:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  right: -30px;
  top: 20%;
  border-left: 30px solid #666;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.result-query-content .queries .message-row.received .message:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  right: -27px;
  top: 20%;
  border-left: 30px solid #f0f0f0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.result-query-content .queries.ukba .message-row .message {
  border-color: #666;
  background-color: #f0f0f0;
}
.result-query-content .queries.ukba .message-row .message.sent:before {
  border-right-color: #666;
}
.result-query-content .queries.ukba .message-row .message.sent:after {
  border-right-color: #f0f0f0;
}
.result-query-content .queries.ukba .message-row .message.received {
  border-color: #5b0053;
  background-color: #fff;
}
.result-query-content .queries.ukba .message-row .message.received:before {
  border-left-color: #5b0053;
}
.result-query-content .queries.ukba .message-row .message.received:after {
  border-left-color: #fff;
}

.react-case-notes-page .filters {
  padding: 20px;
}
.react-case-notes-page .filters .query-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
}
.react-case-notes-page .filters .query-options .filter-option .filter-title {
  display: inline-block;
  padding-left: 5px;
  padding-bottom: 5px;
}
.react-case-notes-page .header-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.react-case-notes-page .stripedbox + .stripedbox {
  margin-top: 10px;
}
.react-case-notes-page .notes-container {
  margin-left: 15px;
  margin-right: 15px;
}
.react-case-notes-page .notes-container .notes-control {
  margin-top: 15px;
}

.notes-container {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  padding-bottom: 5px;
}
.notes-container + .notes-container {
  padding-top: 10px;
}
.notes-container .notes-control {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
  color: #000;
}
.notes-container .notes-control .notes-text {
  padding: 5px;
  min-height: 45px;
  white-space: pre-wrap;
}
.notes-container .notes-control .notes-widget-footer {
  border-top: 1px solid #999;
  padding: 0;
  height: 15px;
  clear: both;
  font-size: 10px;
  font-style: italic;
  color: #999;
  line-height: 20px;
}
.notes-container .notes-control .notes-widget-footer span.purple {
  color: #7C0672;
}
.notes-container .notes-control .notes-widget-footer span.darkGray {
  color: #666;
}
.notes-container .notes-options {
  display: flex;
  justify-content: flex-end;
  grid-gap: 5px;
  gap: 5px;
}

.case-referral-edit-page .referral-edit-form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 10px auto;
  grid-gap: 10px;
  gap: 10px;
}
.case-referral-edit-page .referral-edit-form .input-wrapper {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.case-referral-edit-page .referral-edit-form .input-wrapper label {
  width: 40%;
  line-height: 40px;
}
.case-referral-edit-page .referral-edit-form .input-wrapper .rw-dropdown-list {
  width: 340px;
}
.case-referral-edit-page .referral-edit-form .input-wrapper .rw-dropdown-list input {
  color: #000;
}
.case-referral-edit-page .referral-edit-form .input-wrapper input[type=text],
.case-referral-edit-page .referral-edit-form .input-wrapper textarea {
  width: 319px;
}
.case-referral-edit-page .referral-edit-form .input-wrapper .react-select,
.case-referral-edit-page .referral-edit-form .input-wrapper .notes-container {
  width: 340px;
}
.case-referral-edit-page .referral-edit-form .input-with-error-message {
  max-width: 340px;
}
.case-referral-edit-page hr {
  border-color: #fff;
  border-style: solid;
}
.case-referral-edit-page .form-buttons {
  margin: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}
.case-referral-edit-page .form-buttons .rbtn {
  padding: 8px 12px;
  box-sizing: border-box;
  max-width: 100px;
}

.case-referral-edit-page .referral-edit-form .select-with-options,
.react-modal.reopen-referral .modal-edit-form .select-with-options {
  display: flex;
  flex-direction: column;
}
.case-referral-edit-page .referral-edit-form .select-with-options .select-with-options-buttons,
.react-modal.reopen-referral .modal-edit-form .select-with-options .select-with-options-buttons {
  padding: 10px 0;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.case-referral-edit-page .referral-edit-form .referral-services,
.react-modal.reopen-referral .modal-edit-form .referral-services {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 10px;
}
.case-referral-edit-page .referral-edit-form .referral-services label,
.react-modal.reopen-referral .modal-edit-form .referral-services label {
  width: auto !important;
  line-height: 24px !important;
}
.case-referral-edit-page .referral-edit-form .referral-services .regular-checkbox + label,
.react-modal.reopen-referral .modal-edit-form .referral-services .regular-checkbox + label {
  padding: 10px;
}
.case-referral-edit-page .referral-edit-form .referral-services .input-wrapper,
.react-modal.reopen-referral .modal-edit-form .referral-services .input-wrapper {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}
.case-referral-edit-page .referral-edit-form .referral-services .input-wrapper .regular-checkbox-box,
.react-modal.reopen-referral .modal-edit-form .referral-services .input-wrapper .regular-checkbox-box {
  align-self: initial !important;
}

.case-immigration-edit-page .immigration-edit-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
  gap: 2px;
}
.case-immigration-edit-page .immigration-edit-wrapper .header-with-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.case-immigration-edit-page .immigration-edit-wrapper .header-with-flex .mandatory-text {
  font-size: 11px;
  color: Red;
}
.case-immigration-edit-page .immigration-edit-wrapper .page-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 10px 20px;
}
.case-immigration-edit-page .immigration-edit-wrapper .page-header.alert-header {
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}
.case-immigration-edit-page .immigration-edit-wrapper .page-header.alert-header h2 {
  color: #7C0672;
}
.case-immigration-edit-page .immigration-edit-wrapper .page-header.alert-header .rbtn {
  max-width: 100px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
  gap: 2px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper hr {
  border-color: #fff;
  border-style: solid;
  margin-bottom: 0rem;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .immigration-edit-form {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding: 0px 8px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .immigration-edit-form .section-header {
  margin-bottom: 5px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .immigration-edit-form .section-header > label {
  font-weight: bold;
  color: #333333;
  font-size: 14px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .header-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .left-column,
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .right-column {
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
  gap: 2px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .input-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .input-wrapper label {
  align-self: center;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .input-wrapper label.input-wrapper-with-options-label {
  align-self: auto;
  padding-top: 11px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .input-wrapper .react-datepicker__input-container input {
  width: 194px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .input-wrapper .rw-dropdown-list-value {
  line-height: 39px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .input-wrapper-long-question {
  display: flex;
  justify-content: space-between;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .input-wrapper-long-question label {
  align-self: center;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .input-wrapper-full-width {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .input-wrapper-with-options {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .input-wrapper-with-options .dropdown-options {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  justify-content: flex-end;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .radio-options {
  line-height: 39px;
  display: flex;
  justify-self: end;
  grid-gap: 10px;
  gap: 10px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .radio-options label + input {
  margin-left: 12px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .double-dates {
  display: flex;
  justify-content: space-between;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .double-dates input[type=text] {
  width: 192px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .fake-input {
  white-space: pre-wrap;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .notes-control {
  max-width: 425px;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .queries-control {
  padding: 10px 0;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .rtpf-lookup-button {
  display: flex;
  justify-content: flex-end;
}
.case-immigration-edit-page .immigration-edit-wrapper .immigration-grid-wrapper .rtpf-lookup-button button {
  max-width: 100px;
}
.case-immigration-edit-page .immigration-edit-wrapper .form-buttons {
  padding: 5px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}
.case-immigration-edit-page .immigration-edit-wrapper .form-buttons .rbtn {
  padding: 8px 12px;
  max-width: 100px;
}
.case-immigration-edit-page .immigration-edit-wrapper .queries-control-title {
  margin-top: 10px;
}

.immigration-history .summary-rows {
  width: 90%;
  margin: 8px auto;
  border-collapse: collapse;
  display: table;
}
.immigration-history .summary-rows .header {
  font-size: 12px;
  color: #7C0672;
  display: table-header-group;
}
.immigration-history .summary-rows .header span {
  display: table-cell;
  padding-left: 8px;
}
.immigration-history .summary-rows .body-row {
  display: table-row;
}
.immigration-history .summary-rows .body-row > span {
  display: table-cell;
  background-color: #fff;
  border-top: 1px solid #888;
  border-bottom: 1px solid #ddd;
  padding: 8px;
}
.immigration-history .summary-rows .body-row:last-child > span {
  border-bottom: 1px solid #888;
}

.case-closure-edit-page .closure-edit-form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 10px auto;
  grid-gap: 10px;
  gap: 10px;
}
.case-closure-edit-page .closure-edit-form .input-wrapper {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.case-closure-edit-page .closure-edit-form .input-wrapper label {
  width: 40%;
  line-height: 40px;
}
.case-closure-edit-page .closure-edit-form .input-wrapper .rw-dropdown-list {
  width: 340px;
}
.case-closure-edit-page .closure-edit-form .input-wrapper .rw-dropdown-list input {
  color: #000;
}
.case-closure-edit-page .closure-edit-form .input-wrapper input[type=text] {
  width: 320px;
}
.case-closure-edit-page .closure-edit-form .input-wrapper .react-select,
.case-closure-edit-page .closure-edit-form .input-wrapper .notes-container {
  width: 340px;
}
.case-closure-edit-page .closure-edit-form .input-with-error-message {
  max-width: 340px;
}
.case-closure-edit-page .form-buttons {
  margin: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}
.case-closure-edit-page .form-buttons .rbtn {
  padding: 8px 12px;
  box-sizing: border-box;
  max-width: 100px;
}

.case-closure-history .summary-rows {
  width: 90%;
  margin: 8px auto;
  border-collapse: collapse;
  display: table;
}
.case-closure-history .summary-rows .header {
  font-size: 12px;
  color: #7C0672;
  display: table-header-group;
}
.case-closure-history .summary-rows .header span {
  display: table-cell;
  padding-left: 8px;
}
.case-closure-history .summary-rows .body-row {
  display: table-row;
}
.case-closure-history .summary-rows .body-row > span {
  display: table-cell;
  background-color: #fff;
  border-top: 1px solid #888;
  border-bottom: 1px solid #ddd;
  padding: 8px;
}
.case-closure-history .summary-rows .body-row:last-child > span {
  border-bottom: 1px solid #888;
}

.create-case-wrapper .form-buttons {
  margin-top: 2px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}
.create-case-wrapper .form-buttons.edges {
  justify-content: space-between;
}
.create-case-wrapper .form-buttons .rbtn {
  padding: 8px 12px;
  box-sizing: border-box;
  max-width: 100px;
}
.create-case-wrapper .case-referral-edit-page {
  display: flex;
  grid-gap: 2px;
  gap: 2px;
  flex-direction: column;
}
.create-case-wrapper .page-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px 20px;
}
.create-case-wrapper .page-header .mandatory-text {
  font-size: 11px;
  color: Red;
}

.duplicate-case-step {
  display: flex;
  grid-gap: 2px;
  grid-gap: 2px;
  gap: 2px;
  flex-direction: column;
}
.duplicate-case-step h2.page-description {
  padding: 10px;
}
.duplicate-case-step .duplicate-case-list-item {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 2px solid #fff;
}
.duplicate-case-step .duplicate-case-list-item .case-details-left {
  width: 660px;
  padding: 10px;
}
.duplicate-case-step .duplicate-case-list-item .case-details-left .case-details-flex-row {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.duplicate-case-step .duplicate-case-list-item .case-details-left .case-details-flex-row label {
  width: 127px;
  box-sizing: border-box;
}
.duplicate-case-step .duplicate-case-list-item .case-details-left .case-details-flex-row .fake-input {
  width: 498px;
}
.duplicate-case-step .duplicate-case-list-item .case-details-left .case-details-flex-row + .case-details-flex-row, .duplicate-case-step .duplicate-case-list-item .case-details-left .case-details-flex-row + .case-details-grid-row {
  margin-top: 10px;
}
.duplicate-case-step .duplicate-case-list-item .case-details-left .case-details-grid-row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  grid-gap: 10px;
  gap: 10px;
}
.duplicate-case-step .duplicate-case-list-item .case-details-left .case-details-grid-row label {
  width: 127px;
}
.duplicate-case-step .duplicate-case-list-item .case-details-left .case-details-grid-row .fake-input {
  width: 163px;
}
.duplicate-case-step .duplicate-case-list-item .case-details-left .case-details-grid-row + .case-details-flex-row, .duplicate-case-step .duplicate-case-list-item .case-details-left .case-details-grid-row + .case-details-grid-row {
  margin-top: 10px;
}
.duplicate-case-step .duplicate-case-list-item .case-details-left label {
  line-height: 40px;
}
.duplicate-case-step .duplicate-case-list-item .case-details-right {
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
}
.duplicate-case-step .duplicate-case-list-item .case-details-right .case-details-right-top {
  align-self: end;
  position: relative;
}
.duplicate-case-step .duplicate-case-list-item .case-details-right .case-details-right-top .closed-badge {
  position: absolute;
  top: 45px;
  left: -10px;
  width: 210px;
  height: 122px;
  background: transparent url(/images/case-closed-stamp.png) no-repeat top left;
  z-index: 100;
}
.duplicate-case-step .duplicate-case-list-item .case-details-right .photo-image {
  width: 195px;
}
.duplicate-case-step .duplicate-case-list-item .case-details-right .photo-image img {
  width: 195px;
  height: 195px;
}
.duplicate-case-step .duplicate-case-list-item .case-details-right .photo-image.closed img {
  opacity: 0.3;
}
.duplicate-case-step .duplicate-case-list-item .case-details-right .rbtn {
  max-height: 33px;
}

.react-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s linear, visibility 0.15s linear;
}
.react-modal-backdrop.show {
  opacity: 0.8;
  visibility: visible;
}

.react-modal-anchor {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-modal-anchor.show {
  opacity: 1;
  visibility: visible;
}

.react-modal {
  position: relative;
  margin-top: -2000px;
  min-width: 400px;
  max-width: 100%;
  max-height: 90%;
  overflow: auto;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  transition: top 0.3s ease-out, height 0.3s ease-out, margin 0.3s ease-out;
  border-bottom: 8px solid #6b1e5a;
  padding: 10px;
}
.react-modal.show {
  margin-top: 0;
}
.react-modal .close-button {
  position: absolute;
  display: block;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  color: #fff;
  border-radius: 4px;
  background-color: #7C0672;
}
.react-modal hr {
  border-color: #fff;
  border-style: solid;
}
.react-modal h1 {
  display: flex;
  justify-content: left;
  align-items: center;
  grid-column-gap: 15px;
  column-gap: 15px;
  padding-left: 15px;
  margin: 0 0 10px !important;
  color: #7C0672;
  font-weight: 500;
  line-height: 1.2;
}
.react-modal h2 {
  color: #333333;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}
.react-modal.user-delete p, .react-modal.reset-password p {
  font-weight: bold;
  margin: 1rem;
  white-space: normal;
  white-space: initial;
}
.react-modal.user-delete input[type=text],
.react-modal.user-delete input[type=password],
.react-modal.user-delete select, .react-modal.reset-password input[type=text],
.react-modal.reset-password input[type=password],
.react-modal.reset-password select {
  width: 100%;
}
.react-modal.user-delete form, .react-modal.reset-password form {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  margin: 0;
}
.react-modal.user-delete form .field-row, .react-modal.reset-password form .field-row {
  display: flex;
  flex-direction: row;
}
.react-modal.user-delete .p-15, .react-modal.reset-password .p-15 {
  padding: 0 15px;
}
.react-modal.user-delete .button-row, .react-modal.reset-password .button-row {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}
.react-modal .confirmation {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  margin: 0;
}
.react-modal .confirmation .button-row {
  text-align: center;
}
.react-modal .confirmation .button-row > button {
  max-width: 200px;
}

.react-modal.housing-providers,
.react-modal.aliases,
.react-modal.documents,
.react-modal.educations,
.react-modal.solicitors,
.react-modal.file-owners,
.react-modal.referral-organisations,
.react-modal.add-note,
.react-modal.add-query,
.react-modal.view-query,
.react-modal.update-photo,
.react-modal.reopen,
.react-modal.reopen-referral,
.react-modal.rtpf-description {
  width: 600px;
}
.react-modal.housing-providers .modal-edit-form,
.react-modal.aliases .modal-edit-form,
.react-modal.documents .modal-edit-form,
.react-modal.educations .modal-edit-form,
.react-modal.solicitors .modal-edit-form,
.react-modal.file-owners .modal-edit-form,
.react-modal.referral-organisations .modal-edit-form,
.react-modal.add-note .modal-edit-form,
.react-modal.add-query .modal-edit-form,
.react-modal.view-query .modal-edit-form,
.react-modal.update-photo .modal-edit-form,
.react-modal.reopen .modal-edit-form,
.react-modal.reopen-referral .modal-edit-form,
.react-modal.rtpf-description .modal-edit-form {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 10px auto;
  grid-gap: 10px;
  gap: 10px;
}
.react-modal.housing-providers .modal-edit-form .header-with-button,
.react-modal.aliases .modal-edit-form .header-with-button,
.react-modal.documents .modal-edit-form .header-with-button,
.react-modal.educations .modal-edit-form .header-with-button,
.react-modal.solicitors .modal-edit-form .header-with-button,
.react-modal.file-owners .modal-edit-form .header-with-button,
.react-modal.referral-organisations .modal-edit-form .header-with-button,
.react-modal.add-note .modal-edit-form .header-with-button,
.react-modal.add-query .modal-edit-form .header-with-button,
.react-modal.view-query .modal-edit-form .header-with-button,
.react-modal.update-photo .modal-edit-form .header-with-button,
.react-modal.reopen .modal-edit-form .header-with-button,
.react-modal.reopen-referral .modal-edit-form .header-with-button,
.react-modal.rtpf-description .modal-edit-form .header-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.react-modal.housing-providers .modal-edit-form .input-wrapper,
.react-modal.aliases .modal-edit-form .input-wrapper,
.react-modal.documents .modal-edit-form .input-wrapper,
.react-modal.educations .modal-edit-form .input-wrapper,
.react-modal.solicitors .modal-edit-form .input-wrapper,
.react-modal.file-owners .modal-edit-form .input-wrapper,
.react-modal.referral-organisations .modal-edit-form .input-wrapper,
.react-modal.add-note .modal-edit-form .input-wrapper,
.react-modal.add-query .modal-edit-form .input-wrapper,
.react-modal.view-query .modal-edit-form .input-wrapper,
.react-modal.update-photo .modal-edit-form .input-wrapper,
.react-modal.reopen .modal-edit-form .input-wrapper,
.react-modal.reopen-referral .modal-edit-form .input-wrapper,
.react-modal.rtpf-description .modal-edit-form .input-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: baseline;
}
.react-modal.housing-providers .modal-edit-form .input-wrapper.full-width,
.react-modal.aliases .modal-edit-form .input-wrapper.full-width,
.react-modal.documents .modal-edit-form .input-wrapper.full-width,
.react-modal.educations .modal-edit-form .input-wrapper.full-width,
.react-modal.solicitors .modal-edit-form .input-wrapper.full-width,
.react-modal.file-owners .modal-edit-form .input-wrapper.full-width,
.react-modal.referral-organisations .modal-edit-form .input-wrapper.full-width,
.react-modal.add-note .modal-edit-form .input-wrapper.full-width,
.react-modal.add-query .modal-edit-form .input-wrapper.full-width,
.react-modal.view-query .modal-edit-form .input-wrapper.full-width,
.react-modal.update-photo .modal-edit-form .input-wrapper.full-width,
.react-modal.reopen .modal-edit-form .input-wrapper.full-width,
.react-modal.reopen-referral .modal-edit-form .input-wrapper.full-width,
.react-modal.rtpf-description .modal-edit-form .input-wrapper.full-width {
  display: flex;
  flex-direction: column;
}
.react-modal.housing-providers .modal-edit-form .input-wrapper.full-width textarea,
.react-modal.aliases .modal-edit-form .input-wrapper.full-width textarea,
.react-modal.documents .modal-edit-form .input-wrapper.full-width textarea,
.react-modal.educations .modal-edit-form .input-wrapper.full-width textarea,
.react-modal.solicitors .modal-edit-form .input-wrapper.full-width textarea,
.react-modal.file-owners .modal-edit-form .input-wrapper.full-width textarea,
.react-modal.referral-organisations .modal-edit-form .input-wrapper.full-width textarea,
.react-modal.add-note .modal-edit-form .input-wrapper.full-width textarea,
.react-modal.add-query .modal-edit-form .input-wrapper.full-width textarea,
.react-modal.view-query .modal-edit-form .input-wrapper.full-width textarea,
.react-modal.update-photo .modal-edit-form .input-wrapper.full-width textarea,
.react-modal.reopen .modal-edit-form .input-wrapper.full-width textarea,
.react-modal.reopen-referral .modal-edit-form .input-wrapper.full-width textarea,
.react-modal.rtpf-description .modal-edit-form .input-wrapper.full-width textarea {
  width: 635px;
}
.react-modal.housing-providers .modal-edit-form .input-wrapper label,
.react-modal.aliases .modal-edit-form .input-wrapper label,
.react-modal.documents .modal-edit-form .input-wrapper label,
.react-modal.educations .modal-edit-form .input-wrapper label,
.react-modal.solicitors .modal-edit-form .input-wrapper label,
.react-modal.file-owners .modal-edit-form .input-wrapper label,
.react-modal.referral-organisations .modal-edit-form .input-wrapper label,
.react-modal.add-note .modal-edit-form .input-wrapper label,
.react-modal.add-query .modal-edit-form .input-wrapper label,
.react-modal.view-query .modal-edit-form .input-wrapper label,
.react-modal.update-photo .modal-edit-form .input-wrapper label,
.react-modal.reopen .modal-edit-form .input-wrapper label,
.react-modal.reopen-referral .modal-edit-form .input-wrapper label,
.react-modal.rtpf-description .modal-edit-form .input-wrapper label {
  margin: 0;
  padding: 0;
}
.react-modal.housing-providers .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.aliases .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.documents .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.educations .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.solicitors .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.file-owners .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.referral-organisations .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.add-note .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.add-query .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.view-query .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.update-photo .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.reopen .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.reopen-referral .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.rtpf-description .modal-edit-form .input-wrapper .input-with-error-message {
  width: 100%;
}
.react-modal.housing-providers .modal-edit-form .input-wrapper .input-with-error-message.full-width,
.react-modal.aliases .modal-edit-form .input-wrapper .input-with-error-message.full-width,
.react-modal.documents .modal-edit-form .input-wrapper .input-with-error-message.full-width,
.react-modal.educations .modal-edit-form .input-wrapper .input-with-error-message.full-width,
.react-modal.solicitors .modal-edit-form .input-wrapper .input-with-error-message.full-width,
.react-modal.file-owners .modal-edit-form .input-wrapper .input-with-error-message.full-width,
.react-modal.referral-organisations .modal-edit-form .input-wrapper .input-with-error-message.full-width,
.react-modal.add-note .modal-edit-form .input-wrapper .input-with-error-message.full-width,
.react-modal.add-query .modal-edit-form .input-wrapper .input-with-error-message.full-width,
.react-modal.view-query .modal-edit-form .input-wrapper .input-with-error-message.full-width,
.react-modal.update-photo .modal-edit-form .input-wrapper .input-with-error-message.full-width,
.react-modal.reopen .modal-edit-form .input-wrapper .input-with-error-message.full-width,
.react-modal.reopen-referral .modal-edit-form .input-wrapper .input-with-error-message.full-width,
.react-modal.rtpf-description .modal-edit-form .input-wrapper .input-with-error-message.full-width {
  grid-column: 1/3;
}
.react-modal.housing-providers .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea,
.react-modal.aliases .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea,
.react-modal.documents .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea,
.react-modal.educations .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea,
.react-modal.solicitors .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea,
.react-modal.file-owners .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea,
.react-modal.referral-organisations .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea,
.react-modal.add-note .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea,
.react-modal.add-query .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea,
.react-modal.view-query .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea,
.react-modal.update-photo .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea,
.react-modal.reopen .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea,
.react-modal.reopen-referral .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea,
.react-modal.rtpf-description .modal-edit-form .input-wrapper .input-with-error-message.full-width textarea {
  width: 100%;
}
.react-modal.housing-providers .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.aliases .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.documents .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.educations .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.solicitors .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.file-owners .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.referral-organisations .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.add-note .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.add-query .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.view-query .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.update-photo .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.reopen .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.reopen-referral .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.rtpf-description .modal-edit-form .input-wrapper .input-with-error-message input[type=text] {
  width: auto;
}
.react-modal.housing-providers .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.aliases .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.documents .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.educations .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.solicitors .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.file-owners .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.referral-organisations .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.add-note .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.add-query .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.view-query .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.update-photo .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.reopen .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.reopen-referral .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.rtpf-description .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text] {
  width: 246px;
}
.react-modal.housing-providers .modal-edit-form .input-wrapper .nontextbox,
.react-modal.aliases .modal-edit-form .input-wrapper .nontextbox,
.react-modal.documents .modal-edit-form .input-wrapper .nontextbox,
.react-modal.educations .modal-edit-form .input-wrapper .nontextbox,
.react-modal.solicitors .modal-edit-form .input-wrapper .nontextbox,
.react-modal.file-owners .modal-edit-form .input-wrapper .nontextbox,
.react-modal.referral-organisations .modal-edit-form .input-wrapper .nontextbox,
.react-modal.add-note .modal-edit-form .input-wrapper .nontextbox,
.react-modal.add-query .modal-edit-form .input-wrapper .nontextbox,
.react-modal.view-query .modal-edit-form .input-wrapper .nontextbox,
.react-modal.update-photo .modal-edit-form .input-wrapper .nontextbox,
.react-modal.reopen .modal-edit-form .input-wrapper .nontextbox,
.react-modal.reopen-referral .modal-edit-form .input-wrapper .nontextbox,
.react-modal.rtpf-description .modal-edit-form .input-wrapper .nontextbox {
  width: 100%;
}
.react-modal.housing-providers .modal-edit-form .input-wrapper .radio-options,
.react-modal.aliases .modal-edit-form .input-wrapper .radio-options,
.react-modal.documents .modal-edit-form .input-wrapper .radio-options,
.react-modal.educations .modal-edit-form .input-wrapper .radio-options,
.react-modal.solicitors .modal-edit-form .input-wrapper .radio-options,
.react-modal.file-owners .modal-edit-form .input-wrapper .radio-options,
.react-modal.referral-organisations .modal-edit-form .input-wrapper .radio-options,
.react-modal.add-note .modal-edit-form .input-wrapper .radio-options,
.react-modal.add-query .modal-edit-form .input-wrapper .radio-options,
.react-modal.view-query .modal-edit-form .input-wrapper .radio-options,
.react-modal.update-photo .modal-edit-form .input-wrapper .radio-options,
.react-modal.reopen .modal-edit-form .input-wrapper .radio-options,
.react-modal.reopen-referral .modal-edit-form .input-wrapper .radio-options,
.react-modal.rtpf-description .modal-edit-form .input-wrapper .radio-options {
  display: flex;
  align-items: baseline;
  grid-gap: 10px;
  gap: 10px;
  line-height: 40px;
}
.react-modal.housing-providers .modal-edit-form .document-information,
.react-modal.aliases .modal-edit-form .document-information,
.react-modal.documents .modal-edit-form .document-information,
.react-modal.educations .modal-edit-form .document-information,
.react-modal.solicitors .modal-edit-form .document-information,
.react-modal.file-owners .modal-edit-form .document-information,
.react-modal.referral-organisations .modal-edit-form .document-information,
.react-modal.add-note .modal-edit-form .document-information,
.react-modal.add-query .modal-edit-form .document-information,
.react-modal.view-query .modal-edit-form .document-information,
.react-modal.update-photo .modal-edit-form .document-information,
.react-modal.reopen .modal-edit-form .document-information,
.react-modal.reopen-referral .modal-edit-form .document-information,
.react-modal.rtpf-description .modal-edit-form .document-information {
  margin-left: 20px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 8px;
  gap: 8px;
}
.react-modal.housing-providers .modal-edit-form .form-buttons,
.react-modal.aliases .modal-edit-form .form-buttons,
.react-modal.documents .modal-edit-form .form-buttons,
.react-modal.educations .modal-edit-form .form-buttons,
.react-modal.solicitors .modal-edit-form .form-buttons,
.react-modal.file-owners .modal-edit-form .form-buttons,
.react-modal.referral-organisations .modal-edit-form .form-buttons,
.react-modal.add-note .modal-edit-form .form-buttons,
.react-modal.add-query .modal-edit-form .form-buttons,
.react-modal.view-query .modal-edit-form .form-buttons,
.react-modal.update-photo .modal-edit-form .form-buttons,
.react-modal.reopen .modal-edit-form .form-buttons,
.react-modal.reopen-referral .modal-edit-form .form-buttons,
.react-modal.rtpf-description .modal-edit-form .form-buttons {
  margin: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}
.react-modal.housing-providers .modal-edit-form .form-buttons.edges,
.react-modal.aliases .modal-edit-form .form-buttons.edges,
.react-modal.documents .modal-edit-form .form-buttons.edges,
.react-modal.educations .modal-edit-form .form-buttons.edges,
.react-modal.solicitors .modal-edit-form .form-buttons.edges,
.react-modal.file-owners .modal-edit-form .form-buttons.edges,
.react-modal.referral-organisations .modal-edit-form .form-buttons.edges,
.react-modal.add-note .modal-edit-form .form-buttons.edges,
.react-modal.add-query .modal-edit-form .form-buttons.edges,
.react-modal.view-query .modal-edit-form .form-buttons.edges,
.react-modal.update-photo .modal-edit-form .form-buttons.edges,
.react-modal.reopen .modal-edit-form .form-buttons.edges,
.react-modal.reopen-referral .modal-edit-form .form-buttons.edges,
.react-modal.rtpf-description .modal-edit-form .form-buttons.edges {
  justify-content: space-between;
}
.react-modal.housing-providers .modal-edit-form .form-buttons .rbtn,
.react-modal.aliases .modal-edit-form .form-buttons .rbtn,
.react-modal.documents .modal-edit-form .form-buttons .rbtn,
.react-modal.educations .modal-edit-form .form-buttons .rbtn,
.react-modal.solicitors .modal-edit-form .form-buttons .rbtn,
.react-modal.file-owners .modal-edit-form .form-buttons .rbtn,
.react-modal.referral-organisations .modal-edit-form .form-buttons .rbtn,
.react-modal.add-note .modal-edit-form .form-buttons .rbtn,
.react-modal.add-query .modal-edit-form .form-buttons .rbtn,
.react-modal.view-query .modal-edit-form .form-buttons .rbtn,
.react-modal.update-photo .modal-edit-form .form-buttons .rbtn,
.react-modal.reopen .modal-edit-form .form-buttons .rbtn,
.react-modal.reopen-referral .modal-edit-form .form-buttons .rbtn,
.react-modal.rtpf-description .modal-edit-form .form-buttons .rbtn {
  padding: 8px 12px;
  box-sizing: border-box;
  max-width: 100px;
}

.react-modal.closure-history,
.react-modal.immigration-history,
.react-modal.rtpf-description {
  width: 860px;
}
.react-modal.closure-history textarea,
.react-modal.immigration-history textarea,
.react-modal.rtpf-description textarea {
  width: auto;
}

.react-modal.reopen,
.react-modal.reopen-referral {
  width: 750px;
}
.react-modal.reopen .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text],
.react-modal.reopen-referral .modal-edit-form .input-wrapper .input-with-error-message .react-datepicker-wrapper input[type=text] {
  width: 307px;
}

.react-modal.add-query {
  width: 880px;
}
.react-modal.add-query .modal-edit-form {
  grid-gap: 20px;
  gap: 20px;
}
.react-modal.add-query .modal-edit-form .input-wrapper {
  display: block;
}
.react-modal.add-query .modal-edit-form .input-wrapper .input-with-error-message,
.react-modal.add-query .modal-edit-form .input-wrapper .fake-input {
  display: block;
  width: 100%;
  margin-top: 8px;
}
.react-modal.add-query .modal-edit-form .input-wrapper .input-with-error-message input[type=text],
.react-modal.add-query .modal-edit-form .input-wrapper .fake-input input[type=text] {
  width: 100%;
}

.rbtn {
  display: flex;
  color: #fff;
  padding: 6px 12px;
  border-radius: 4px !important;
  border: 1px solid #6b1e5a;
  flex: 1 1 auto;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-size: 13.6px !important;
  line-height: normal !important;
  cursor: pointer;
  box-sizing: border-box;
}
.rbtn svg {
  flex: 0 1 auto;
}
.rbtn span {
  flex: 1 4 auto;
}
.rbtn.large {
  padding: 8px 14px;
  font-size: 15px;
}
.rbtn.primary {
  background-color: #7c0672;
}
.rbtn.primary:active:not(:disabled) {
  background-color: white;
  color: #7c0672;
  border-color: #aaa;
}
.rbtn.primary.small {
  height: 22px;
  align-self: center;
  font-size: 12px !important;
  padding: 3px 6px;
  flex: none;
}
.rbtn.primary.selected, .rbtn.primary.selected:active {
  background-color: white;
  color: #000;
  border: 2px solid orange;
}
.rbtn.secondary {
  background-color: #666;
  border-color: #555;
}
.rbtn.secondary:active:not(:disabled) {
  background-color: white;
  color: #666;
  border-color: #555;
}
.rbtn.light-grey {
  color: #666;
  background-color: #eee;
  border-color: #ddd;
}
.rbtn.light-grey:active:not(:disabled) {
  background-color: #666;
  color: #eee;
  border-color: #ddd;
}
.rbtn.green {
  background-color: #01aa12;
  border: none;
}
.rbtn.green:active:not(:disabled) {
  border-color: #aaa;
}
.rbtn.green:hover {
  background-color: #029b11;
}
.rbtn.text-link {
  color: #7c0672;
  background-color: transparent;
  border: none;
  padding: 0;
  flex: none;
}
.rbtn.text-link:active:not(:disabled) {
  background-color: #7c0672;
  color: #fff;
}
.rbtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.rbtn.link {
  display: inline;
  display: initial;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #7c0672;
  padding: 0;
}
.rbtn.link.right {
  float: right;
  margin-right: 10px;
}
.rbtn.link.small {
  font-size: 12px;
}
.rbtn.link.with-mo:hover {
  text-decoration: underline;
}
.rbtn.right {
  float: right;
}

.as-href {
  background: none;
  border: none;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.rbtn.hover-tooltip:after {
  width: 350px;
}
.rbtn.hover-tooltip:hover:before {
  transform: translate3d(-50%, calc(-100% - 20px), 0);
}
.rbtn.hover-tooltip:hover::after {
  transform: translate3d(-50%, calc(-100% - 12px), 0);
}

.fake-input {
  padding: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background-color: #ffffff;
  color: #888;
  min-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-with-error-message {
  display: flex;
  flex-direction: column;
}

.input-with-options {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.input-validation-error {
  border: 1px solid #ff0000;
  background-color: #fff8f8 !important;
  border-radius: 5px;
}
.input-validation-error > input {
  background-color: #fff8f8;
}

.field-validation-error {
  margin-top: 5px;
  color: #ff0000;
  font-size: 10px;
}

.is-highlighted {
  border: 2px solid orange !important;
}

.is-highlighted-dropdown .react-select__control {
  border: 2px solid orange !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.react-datepicker {
  box-shadow: 6px 6px 20px -5px #888;
}
.react-datepicker .custom-header {
  margin: 10px;
  display: flex;
  justify-content: center;
  grid-gap: 2px;
  gap: 2px;
}
.react-datepicker .custom-header button {
  padding: 5px;
}
.react-datepicker .custom-header select {
  width: auto;
  padding: 5px;
}

.react-select__control,
.react-multiselect__control {
  min-height: 41px !important;
}
.react-select__control .react-multiselect__multi-value,
.react-multiselect__control .react-multiselect__multi-value {
  max-width: 190px;
}
.react-select__control .react-select__indicator-separator,
.react-select__control .react-multiselect__indicator-separator,
.react-multiselect__control .react-select__indicator-separator,
.react-multiselect__control .react-multiselect__indicator-separator {
  display: none;
}
.react-select__control .react-select__dropdown-indicator,
.react-select__control .react-multiselect__dropdown-indicator,
.react-multiselect__control .react-select__dropdown-indicator,
.react-multiselect__control .react-multiselect__dropdown-indicator {
  max-width: 30px;
}
.react-select__control.react-select__control--is-disabled,
.react-multiselect__control.react-select__control--is-disabled {
  background-color: #fff;
  border-color: #dbdbdb;
  cursor: not-allowed;
  pointer-events: initial;
}
.react-select__control.react-select__control--is-disabled:hover,
.react-multiselect__control.react-select__control--is-disabled:hover {
  border-color: #dbdbdb;
}
.react-select__control.react-select__control--is-disabled .react-select__single-value,
.react-multiselect__control.react-select__control--is-disabled .react-select__single-value {
  color: #545454;
}

.fake-input,
.disabled-text {
  color: #545454 !important;
}

.react-select__control--menu-is-open,
.react-multiselect__control--menu-is-open {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.react-select__menu,
.react-multiselect__menu {
  margin-top: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.react-select__menu .react-select__option--is-focused,
.react-select__menu .react-select__option--is-focused:active,
.react-select__menu .react-select__option--is-selected,
.react-select__menu .react-select__option--is-selected:active,
.react-select__menu .react-multiselect__option--is-focused,
.react-select__menu .react-multiselect__option--is-focused:active,
.react-select__menu .react-multiselect__option--is-selected,
.react-select__menu .react-multiselect__option--is-selected:active,
.react-multiselect__menu .react-select__option--is-focused,
.react-multiselect__menu .react-select__option--is-focused:active,
.react-multiselect__menu .react-select__option--is-selected,
.react-multiselect__menu .react-select__option--is-selected:active,
.react-multiselect__menu .react-multiselect__option--is-focused,
.react-multiselect__menu .react-multiselect__option--is-focused:active,
.react-multiselect__menu .react-multiselect__option--is-selected,
.react-multiselect__menu .react-multiselect__option--is-selected:active {
  background-color: #7C0672;
  color: #fff;
}

.has-groups .react-select__menu .react-select__group-heading,
.has-groups .react-select__menu .react-multiselect__group-heading,
.has-groups .react-multiselect__menu .react-select__group-heading,
.has-groups .react-multiselect__menu .react-multiselect__group-heading {
  font-weight: bold;
  text-transform: none;
  font-size: 1em;
  color: #000;
}
.has-groups .react-select__menu .react-select__option,
.has-groups .react-select__menu .react-multiselect__option,
.has-groups .react-multiselect__menu .react-select__option,
.has-groups .react-multiselect__menu .react-multiselect__option {
  padding-left: 2.25em;
}

.regular-checkbox {
  display: none;
}

.regular-checkbox-label {
  line-height: 22px;
  cursor: pointer;
}

.regular-checkbox + label {
  background-color: #fff;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  width: 0px;
  height: 0px;
  cursor: pointer;
}

.regular-checkbox:disabled + label {
  cursor: not-allowed;
}

.regular-checkbox + label:active, .regular-checkbox:checked + label:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.regular-checkbox:checked + label {
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #99a1a7;
}

.regular-checkbox:checked + label:after {
  content: "✔";
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 4px;
  color: #99a1a7;
}

.big-checkbox-label {
  line-height: 30px;
}

.big-checkbox + label {
  padding: 14px;
}

.big-checkbox:checked + label:after {
  font-size: 20px;
  left: 6px;
}

.regular-radio {
  display: none;
}

.regular-radio + label {
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
}

.regular-radio:disabled + label {
  cursor: not-allowed;
}

.regular-radio:checked + label:after {
  content: " ";
  width: 12px;
  height: 12px;
  border-radius: 50px;
  position: absolute;
  top: 3px;
  background: #99a1a7;
  text-shadow: 0px;
  left: 3px;
  font-size: 32px;
}

.regular-radio:checked + label {
  color: #99a1a7;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.regular-radio + label:active, .regular-radio:checked + label:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.big-radio + label {
  padding: 14px;
}

.big-radio:checked + label:after {
  width: 20px;
  height: 20px;
  left: 4px;
  top: 4px;
}

.rw-widget-input.rw-state-disabled,
.rw-state-disabled .rw-widget-input,
fieldset[disabled] .rw-widget-input {
  background-color: #fff;
}

.alert-message {
  white-space: normal;
  white-space: initial;
}
.alert-message.error {
  margin: 10px;
  padding: 5px;
  padding-left: 10px;
  border: 1px solid #ff0000;
  background-color: #fde5e5;
  border-radius: 5px;
  color: #ff0000;
}
.alert-message.success {
  margin: 10px;
  padding: 5px;
  padding-left: 10px;
  background-color: #e5fde5;
  border-radius: 5px;
  color: green;
  border: 1px solid green;
}

/*# sourceMappingURL=index.css.map */

